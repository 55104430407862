import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bee2 from "../images/Asset_2Honeybee-2.svg"
import aboutImage1 from "../images/about-image-1.png"
import aboutImage2 from "../images/about-image-2.png"
import aboutImage3 from "../images/about-image-3.png"
import pepperIcon from "../images/Asset 3pepper.svg"
import radishIcon from "../images/Asset 1radish.svg"
import pumpkinIcon from "../images/Asset 2pumpkin.svg"
import './about.less';
import { appUrl } from "../helpers/helpers"
const ctaUrl = `${appUrl}/orders/new?source=produceplan.com`;

const AboutPage = () => (
  <Layout>
    <SEO title="About Us"></SEO>

    <div id="about-container">
      <div className="about-section-1">
        <div className="container row">
          <div className="about-section-1-left column">
            <h2 className="title-font-size">
              Empowering small businesses to grow
            </h2>
            <img
              src={bee2}
              alt="Produceplan"
              height={80}
              className="hide-on-small-and-down"
              style={{ textAlign: 'center', position: 'absolute', top: 160, left: '30%', width:60 }}
            />
            <div className="desc-font-size">
              Every company needs the right tools to blossom. To scale your business, you need software that’s
              accessible,
              offers the right features, is quick to master and highlights key opportunities. Especially in the fresh
              produce business.
              Produceplan™ is designed to help your business grow. For over 7 years, we’ve been committed to building the
              best ERP – a ERP by and for produce people. The result is an easy-to-use, effective software tool that
              centralizes your data, helping you visualize your entire supply chain process and win more deals with
              happier customers.
            </div>

          </div>
          <div className="about-section-1-right column">
            <img
              src={aboutImage1}
              alt="Produceplan"
            />
          </div>
        </div>
      </div>
      <div className="about-section-2">
        <div className="container row">
          <div className="about-section-2-left column">
            <img
              src={aboutImage2}
              alt="Produceplan"
            />
          </div>
          <div className="about-section-2-right column">
            <h3 className="title-font-size">
              An ERP built by produce people, for produce people
            </h3>
            <div className="desc-font-size">
              Our story began 7 years ago. At Produceplan™ we originally built an easy-to-use, visual sales, harvesting
              and shipping platform to help users streamline their processes and get more done.
              Since then, we’ve grown into a full-scale ERP with accounting, transportation management, traceability,
              and ledger management. Our team of people across four countries work tirelessly toward one goal:
              empowering fresh produce companies everywhere to succeed.
              Backed by professional investors and with the support of the agricultural community, we continuously
              strive to push the boundaries of what an easy-to-use and effective ERP can do to help small, medium and
              large businesses grow.
            </div>
            <img
              src={radishIcon}
              alt="Produceplan"
              height={80}
              className="hide-on-small-and-down"
              style={{ textAlign: "center", position: "absolute", top: -60, right: "30%", height: 140 }}
            />

          </div>

        </div>
      </div>
      <div className="about-section-3">
        <div className="container row">
          <div className="about-section-3-left column">
            <h3 className="title-font-size">
              Our ERP mission statement
            </h3>
            <div className="desc-font-size">
              Produceplan™ is the easy and effective ERP for small and medium-sized companies who want to stay ahead of
              the curve.
              We empower SMBs to unlock their business potential and scale with our easy-to-use, affordable and
              effective ERP.
              With Produceplan™, you can track your sales pipeline, manage inventory, freight and farms and automate your
              entire supply chain process from one central place – so you can focus on selling. Stay organized,
              productive and efficient, with access to multiple API integrations, automation, reporting, dashboards and
              more.
              We invite you take a look around the rest of our website and our application, and welcome any questions, comments or feedback that you may have. Thanks for stopping by and have a great rest of your day!
            </div>
            <img
              src={pumpkinIcon}
              alt="Produceplan"
              height={80}
              className="hide-on-small-and-down"
              style={{ textAlign: "center", position: "absolute", top: -60, left: "26%", height: 100 }}
            />

          </div>
          <div className="about-section-3-right column">
            <img
              src={aboutImage3}
              alt="Produceplan"
            />
          </div>
        </div>
      </div>
      <div className="about-section-4">
        <div className="container row">
          <div className="about-section-4-left column">
            <div>
              <h2 style={{ fontWeight: 600, fontSize: 34 }}>
                Come and say hello
              </h2>
              <div style={{ fontWeight: 600 }}>
                <p>Interested in visiting our offices? We’d be more than happy to show you around!</p>
                <p>Email us (in English) at <a href="mailto:marketing@produceplan.com">marketing@produceplan.com</a> and
                  we’ll be in touch.</p>

              </div>
            </div>
          </div>
          <div className="about-section-4-right column">
            <div>
              <p style={{ fontWeight: 800, fontSize:18 }}>Headquarters</p>
              <p style={{ fontWeight: 600 }}>
                Sostena Inc. <br />
                1201 J Street, Suite 200 <br />
                California, 95814 <br />
                United States
              </p>

            </div>
          </div>
          <img
            src={pepperIcon}
            alt="Produceplan"
            height={80}
            className="hide-on-small-and-down"
            style={{ textAlign: "center", position: "absolute", top: "10%", left: "54%", zIndex: 950 }}
          />
        </div>
      </div>

      <div className="about-section-5">
        <div className="container column">
          <p>Ready to see Produceplan™ in action?</p>
          <a
            href={ctaUrl}
            className="btn btn-green btn-shadowless btn-rounded green"
          >
            Get started →
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
